<template>
    <v-form ref="krankenForm">
        <v-alert
                border="top"
                colored-border
                color="secondary"
        >
            <h3 class="headline">
                Krankenversicherung
            </h3>
            <div>Füllen Sie bitte folgende Daten zu ihrer Versicherung aus.</div>
        </v-alert>
        <v-container>
            <v-row>
                <v-col cols="12" md="4">
                    <v-text-field
                            label="Sozialversicherungsträger (SVA, ÖGK, BVA ….)"
                            v-model="sozialversicherung"
                            type="text"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                            label="Arbeitgeber"
                            v-model="arbeitgeber"
                            :rules="genericRules"
                            type="text"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="privatarzt" :rules="genericRules"
                                   :persistent-hint="true"
                    hint="Der ambulante Tarif, auch Wahlarztversicherung genannt, deckt die Kosten, die im Zusammenhang mit der ambulanten Behandlung (in der Praxis) durch einen Wahlarzt/Privatarzt entstehen. Durch die steigende Zahl der Wahlärzte in Österreich findet dieser Tarif immer größer werdende Beliebtheit. ">
                        <template v-slot:label>
                            <div>Privatarzttarif inklusive Leistungen für Physiotherapie, Psychotherapie & Arzneimittel sowie Heilbehelfe </div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="privatarztplus" :rules="genericRules">
                        <template v-slot:label>
                            <div>Privatarzttarif inkl. Therapien, Impfungen, Sehbehelfe</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="sonderklasse" :rules="genericRules">
                        <template v-slot:label>
                            <div>Sonderklasseversicherung im Spital</div>
                        </template>
                        <v-radio
                                label="Ja ohne Selbstbehalt"
                                value="Ja ohne Selbstbehalt"
                        ></v-radio>
                        <v-radio
                                label="Ja mit Selbstbehalt"
                                value="Ja mit Selbstbehalt"
                        ></v-radio>
                        <v-radio
                                label="Zweibettzimmer"
                                value="Zweibettzimmer"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="zahntarif" :rules="genericRules">
                        <template v-slot:label>
                            <div>Zahntarif</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="einbettzimmer" :rules="genericRules">
                        <template v-slot:label>
                            <div>Einbettzimmer</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="baby" :rules="genericRules">
                        <template v-slot:label>
                            <div>Baby Option (zbs. Einschluss Kind ab Geburt) </div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="taggeld" :rules="genericRules"
                                   :persistent-hint="true"
                    hint="Spitalgeld wird für jeden Tag eines Krankenhausaufenthalts gezahlt.">
                        <template v-slot:label>
                            <div>Spitalgeld</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="reiseversicherung" :rules="genericRules">
                        <template v-slot:label>
                            <div>Reiseversicherung</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="3">
                    <v-radio-group v-model="schwangerschaft" :rules="genericRules">
                        <template v-slot:label>
                            <div>Besteht bereits eine Schwangerschaft und soll diese evtl. mitversichert werden?</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                            label="Sollen weitere Personen (zbs. Lebensgefährte/in / Kind/er) mitversichert werden ? Wenn ja, benötigen wir bitte Name, Geb.Datum, Sozialversicherungsträger "
                            hint="Sollen weitere Personen (zbs. Lebensgefährte/in / Kind/er) mitversichert werden ? Wenn ja, benötigen wir bitte Name, Geb.Datum, Sozialversicherungsträger "
                            v-model="weitere"
                            type="text"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="wellness" :rules="genericRules" hint="Vielen Versicherer bieten als Zusatz zur Krankenversicherung sogenannte Wellness Pakete an. Dabei können Sie ungefähr alle zwei Jahre eine Wellness Angebot in Anspruch nehmen. Typische Beispiele für solche Angebote sind Aufenthalte in einem Wellnesshotel, mehrmonatige Mitgliedschaften in einem Fitnessstudio oder umfangreiche Gesundenuntersuchungen."
                    :persistent-hint="true">
                        <template v-slot:label>
                            <div>Wellness Tarif </div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                            label="Krankengeld für Selbstständig Ja/Nein"
                            :persistent-hint="true"
                            hint="Krankengeld für Selbstständige Wenn sie aufgrund von Krankheit oder Unfall vorübergehend arbeitsunfähig werden, können Sie mit Hilfe eines Krankengeldes Ihre Verdienstausfälle (zumindest teilweise) kompensieren. Der Betrag liegt wahlweise im Bereich von 20 bis 100 Euro pro Tag.Wenn ja, wie hoch ? ( € 20, 40 , 60 , 80 , 100)"
                            v-model="selbststandig"
                            type="text"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-radio-group v-model="wartefrist" :rules="genericRules" hint="Wählen Sie den ungefähren Zeitraum für die Wartefrist bis die Auszahlung des Krankengeldes beginnt.
            Bis zu einer Woche, zwei Wochen, drei Wochen, oder über drei Wochen
"
                                   :persistent-hint="true">
                        <template v-slot:label>
                            <div>Wartefrist beim Krankengeld</div>
                        </template>
                        <v-radio
                                label="Bis zu einer Woche"
                                value="Bis zu einer Woche"
                        ></v-radio>
                        <v-radio
                                label="Bis zu zwei Woche"
                                value="Bis zu zwei Woche"
                        ></v-radio>
                        <v-radio
                                label="Bis zu drei Woche"
                                value="Bis zu drei Woche"
                        ></v-radio>
                        <v-radio
                                label="Über drei Woche"
                                value="Über drei Woche"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "Krankenversicherung",
        data() {
            return {
                genericRules: [
                (v) => !!v || 'Muss ausgefüllt werden'
            ]
            }
        },
        methods:{
            checkFormValid() {
                return this.$refs.krankenForm.validate();
            },
        },
        computed: {
            ...mapFields([
                'sozialversicherung',
                'arbeitgeber',
                'deckung',
                'privatarzt',
                'privatarztplus',
                'sonderklasse',
                'zahntarif',
                'einbettzimmer',
                'baby',
                'taggeld',
                'reiseversicherung',
                'schwangerschaft',
                'schwangerschaftMonat',
                'versicherungValid',
                'weitere',
                'wellness',
                'wartefrist',
                'selbststandig'
    ]),
        }
    }
</script>

<style scoped>

</style>
