<template>
    <v-form ref="haushaltsForm">
        <v-alert
                border="top"
                colored-border
                color="secondary"
        >
            <h3 class="headline">
                Haushaltsversicherung
            </h3>
            <div>Füllen Sie bitte folgende Daten zu ihrer Versicherung aus.</div>
        </v-alert>
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="m² Anzahl Wohnung ?"
                            v-model="quadratMeter"
                            :rules="genericRules"
                            suffix="m²"
                            type="number"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="genaue Risikoadresse / Anschrift ?"
                            v-model="risikoAdresse"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                            label="Loggia vorhanden ? Wenn ja, wie groß? "
                            v-model="loggia"
                            :rules="genericRules"
                            :persistent-hint="true"
                            hint="Im Gegensatz zum Balkon ist die Loggia von zwei bis drei Seiten von Hauswänden umgeben. Daher ist sie gut vor Wind und Wetter geschützt.
Die Loggia ist damit ein Zimmer im Freien ohne Fenster und zählt daher zum Wohnungsinhalt.
"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="mitGarten"  :rules="genericRules">
                        <template v-slot:label>
                            <div>Wohnung mit Garten ?</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Wohnung auch teilweise als Büro genutzt? Wenn ja, wieviel m2 werden als Büro genutzt?"
                            v-model="wohnungBuro"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="weitereRaume"  :rules="genericRules">
                        <template v-slot:label>
                            <div>Weitere Räume wie Abstellräume , Keller, Dachboden oder andere Räumlichkeiten vorhanden?</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="einZweiFamilien"  :rules="genericRules">
                        <template v-slot:label>
                            <div>Befindet sich die Wohnung in einem Ein- / Zweifamilienhaus oder Mehrfamilienhaus die mehr als 270 Tage bewohnt wird ?</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="sicherheitstur"  :rules="genericRules">
                        <template v-slot:label>
                            <div>Sicherheitstüre gemäß ÖNORM B 5338 vorhanden ? (Zertifikat/Rechnung müsste vorhanden sein)</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                            label="Sind Wertgegenstände, wie  z.B.: Uhren, Schmuck, Gold, Briefmarken, Münzsammlungen oder dergleichen vorhanden und haben diese einen Gesamtwert von über € 15.000? Falls ja, wie hoch?"
                            v-model="wertgegenstande"
                            :rules="genericRules"
                            :persistent-hint="true"
                            hint="Tipp:  Zur Sicherheit die Wertgegenstände mit Fotos und einer Aufstellung – Beschreibung -Alter-Wert dokumentieren"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">

                    <template>
                        <v-container fluid>
                    <v-radio-group v-model="bargeld" column  :rules="genericRules">
                        <template v-slot:label>
                            <div>Bargeld unter einfachen Verschluss (nicht sichtbar) über € 6.000? </div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                        </v-container>
                    </template>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                            label="Spezielle Wertegegenstände /Einzelstücke von hohem Wert? Z.B.: Musikinstrumente? Falls ja, wie hoch ist der Anschaffungswert?"
                            hint="Spezielle Wertegegenstände /Einzelstücke von hohem Wert? Z.B.: Musikinstrumente? Falls ja, wie hoch ist der Anschaffungswert?"
                            v-model="spezielle"
                            :rules="genericRules"
                            required
                            type="number"
                            suffix="€"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <template>
                        <v-text-field
                                label="Gibt es einen Safe, falls ja Sicherheitsklasse, Marke, Foto vom Zertifikat ?
              Einfacher Bauhaus Safe oder Burgwächter, Wertheim etc. ?
              Ist dieser eingemauert, angeschraubt bzw. wie ist dieser eingebaut ?
              Ein Foto des Zertifikates, der Innenseite des Safes mit Sicherheitsklasse/Name
              (falls vorhanden), generell Aussagekräftige Fotos wären von Vorteil

"
                                v-model="safe"
                                hint="Gibt es einen Safe, falls ja Sicherheitsklasse, Marke, Foto vom Zertifikat ?
              Einfacher Bauhaus Safe oder Burgwächter, Wertheim etc. ?
              Ist dieser eingemauert, angeschraubt bzw. wie ist dieser eingebaut ?
              Ein Foto des Zertifikates, der Innenseite des Safes mit Sicherheitsklasse/Name
              (falls vorhanden), generell Aussagekräftige Fotos wären von Vorteil

"
                                required
                                :rules="genericRules"
                        ></v-text-field>
                    </template>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Antiquitäten, Kunstgegenstände oder andere erwähnenswerte Objekte von speziellem oder künstlerischem Wert/Verkehrswert? Falls ja, in welcher Höhe?
- Wenn eine extra Kunstversicherung gewünscht wird, benötigen wir eine genaue Auflistung mit Wertangaben)
"
                            v-model="antiquitaten"
                            hint="Antiquitäten, Kunstgegenstände oder andere erwähnenswerte Objekte von speziellem oder künstlerischem Wert/Verkehrswert? Falls ja, in welcher Höhe?
- Wenn eine extra Kunstversicherung gewünscht wird, benötigen wir eine genaue Auflistung mit Wertangaben)
"
                            required
                            type="number"
                            :rules="genericRules"
                            suffix="€"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="(Elektro) Fahrräder/Rennräder oder sonstige spezielle Sportgeräte, welche einen hohen Wert haben? Falls ja, bitte um Alter und Wertangabe"
                            v-model="fahrrader"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Haustiere ? Hunde, Katzen , etc."
                            v-model="haustiere"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Spezielle Verglasungen vorhanden ?-  Kunstverglasungen, Dachverglasungen , Loggia/Balkonverglasungen ?  "
                            v-model="verglasung"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Gibt es Punkte, welche nicht im Rahmen des Risikoerhebungsbogens abgedeckt worden sind und welche Sie für erwähnenswert halten bzw. mitversichern wollen? Falls ja, bitte um kurze Beschreibung"
                            hint="Gibt es Punkte, welche nicht im Rahmen des Risikoerhebungsbogens abgedeckt worden sind und welche Sie für erwähnenswert halten bzw. mitversichern wollen? Falls ja, bitte um kurze Beschreibung"
                            v-model="sonstiges"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-text-field
                            label="Bewohnen Sie die Wohnung alleine oder mit Ehefrau, Kinder, Lebensgefährtin, anderen Verwandten, Mitbewohner /Kollegen ? Bitte das Alter des Kindes oder der Kinder angeben "
                            v-model="alleine"
                            :rules="genericRules"
                            hint="Bewohnen Sie die Wohnung alleine oder mit Ehefrau, Kinder, Lebensgefährtin, anderen Verwandten, Mitbewohner /Kollegen ? Bitte das Alter des Kindes oder der Kinder angeben "
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="6">
                    <template>
                        <v-container fluid>
                            <v-radio-group v-model="schlussel" column  :rules="genericRules">
                                <template v-slot:label>
                                    <div>Soll optional Schlüsselverlust versichert werden? </div>
                                </template>
                                <v-radio
                                        label="Ja"
                                        value="Ja"
                                ></v-radio>
                                <v-radio
                                        label="Nein"
                                        value="Nein"
                                ></v-radio>
                            </v-radio-group>
                        </v-container>
                    </template>
                </v-col>
                <v-col cols="12" md="3"  sm="6">
                    <template>
                        <v-container fluid>
                            <v-radio-group v-model="gerate" column  :rules="genericRules">
                                <template v-slot:label>
                                    <div>Besteht Interesse an einer Geräteversicherung ?</div>
                                </template>
                                <v-radio
                                        label="Ja"
                                        value="Ja"
                                ></v-radio>
                                <v-radio
                                        label="Nein"
                                        value="Nein"
                                ></v-radio>
                            </v-radio-group>
                        </v-container>
                        </template>
                </v-col>
                <v-col cols="12" md="3"  sm="6">
                    <template>
                        <v-container fluid>
                            <v-radio-group v-model="selbstbehalt" column :rules="genericRules">
                                <template v-slot:label>
                                    <div>Selbstbehalt gewünscht ?</div>
                                </template>
                                <v-radio
                                        label="Ja"
                                        value="Ja"
                                ></v-radio>
                                <v-radio
                                        label="Nein"
                                        value="Nein"
                                ></v-radio>
                            </v-radio-group>
                        </v-container>
                    </template>
                </v-col>
                <v-col cols="12" md="3"  sm="6">
                    <template>
                        <v-container fluid>
                            <v-radio-group v-model="ausstattung" column  :rules="genericRules">
                                <template v-slot:label>
                                    <div>Ausstattungskategorie</div>
                                </template>
                                <v-radio
                                        label="Einfach"
                                        value="Einfach"
                                ></v-radio>
                                <v-radio
                                        label="Solide"
                                        value="Solide"
                                ></v-radio>
                                <v-radio
                                        label="Gehoben"
                                        value="Gehoben"
                                ></v-radio>
                            </v-radio-group>
                        </v-container>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "Haushaltsversicherung",
        data() {
            return {
                menu: false,
                testvar: null,
                genericRules: [
                    (v) => !!v || 'Muss ausgefüllt werden'
                ],
                nameRules: [
                    v => !!v || 'Name wird benötigt'
                ],
            }
        },
        methods: {
            checkFormValid() {
                return this.$refs.haushaltsForm.validate();
            }
        },
        computed: {
            ...mapFields([
                'quadratMeter',
                'risikoAdresse',
                'mitGarten',
                'loggia',
                'wohnungBuro',
                'weitereRaume',
                'einZweiFamilien',
                'sicherheitstur',
                'wertgegenstande',
                'bargeld',
                'safe',
                'spezielle',
                'antiquitaten',
                'fahrrader',
                'haustiere',
                'verglasung',
                'sonstiges',
                'alleine',
                'schlussel',
                'gerate',
                'selbstbehalt',
                'ausstattung'
            ]),
        }
    }
</script>

<style scoped>

</style>
