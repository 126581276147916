export default class KrankenTemplates{
    getKrankenTable(state){
        return `
            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
              <tbody>
                <tr>
                  <td align="left">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td> 
                               <table class="styled-table">
                                    <thead>
                                        <tr>
                                            <th>Eintrag</th>
                                            <th>Auswahl</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>sozialversicherungsträger (SVA, ÖGK, BVA ….)</td>
                                            <td>${state.sozialversicherung}</td>
                                        </tr>
                                        <tr>
                                            <td>Arbeitgeber ?</td>
                                            <td>${state.arbeitgeber}</td>
                                        </tr>
                                        <tr>
                                            <td>Welche Deckung wird gewünscht ? </td>
                                            <td>${state.deckung}</td>
                                        </tr>
                                        <tr>
                                            <td>Privatarzttarif</td>
                                            <td>${state.privatarzt}</td>
                                        </tr>
                                        <tr>
                                            <td>Privatarzttarif inkl. Therapien, Impfungen, Sehbehelfe</td>
                                            <td>${state.privatarztplus}</td>
                                        </tr>
                                        <tr>
                                            <td>Sonderklasseversicherung im Spital</td>
                                            <td>${state.sonderklasse}</td>
                                        </tr>
                                        <tr>
                                            <td>Zahntarif</td>
                                            <td>${state.zahntarif}</td>
                                        </tr>
                                        <tr>
                                            <td>Einbettzimmer</td>
                                            <td>${state.einbettzimmer}</td>
                                        </tr>
                                        <tr>
                                            <td>Baby Option (zbs. Einschluss Kind ab Geburt)</td>
                                            <td>${state.baby}</td>
                                        </tr>
                                        <tr>
                                            <td>Spitalgeld</td>
                                            <td>${state.taggeld}</td>
                                        </tr>
                                        <tr>
                                            <td>Reiseversicherung</td>
                                            <td>${state.reiseversicherung}</td>
                                        </tr>
                                        <tr>
                                            <td>Besteht bereits eine Schwangerschaft und soll diese evtl. mitversichert werden?</td>
                                            <td>${state.schwangerschaft}</td>
                                        </tr>
                                        <tr>
                                            <td>Sollen weitere Personen (zbs. Lebensgefährte/in / Kind/er) mitversichert werden ? Wenn ja, benötigen wir bitte Name, Geb.Datum, Sozialversicherungsträger </td>
                                            <td>${state.weitere}</td>
                                        </tr>
                                        <tr>
                                            <td>Wellness</td>
                                            <td>${state.wellness}</td>
                                        </tr>
                                        <tr>
                                            <td>Krankengeld für Selbstständige</td>
                                            <td>${state.selbststandig}</td>
                                        </tr>
                                        <tr>
                                            <td>Wartefrist beim Krankengeld  </td>
                                            <td>${state.wartefrist}</td>
                                        </tr>
                                    </tbody>
                                </table>
                           </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>`;
    }
}
