<template>
    <v-form ref="eigenheimForm">
        <v-alert
                border="top"
                colored-border
                color="secondary"
        >
            <h3 class="headline">
                Eigenheimversicherung
            </h3>
            <div>Füllen Sie bitte folgende Daten zu ihrer Versicherung aus.</div>
        </v-alert>
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="genaue Risikoadresse / Anschrift ?"
                            v-model="risikoAdresse"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Baujahr"
                            v-model="baujahr"
                            :rules="genericRules"
                            required
                            type="number"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="bewohnt" :rules="genericRules">
                        <template v-slot:label>
                            <div>Ist das Gebäude mehr als 270 Tage über Nacht bewohnt im Jahr ? (Oder dient es als Sommerhaus /Zweitwohnsitz und ist weniger als 270 Tage bewohnt)</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="einfamilien" :rules="genericRules">
                        <template v-slot:label>
                            <div>Ein- oder Mehrfamilienhaus?</div>
                        </template>
                        <v-radio
                                label="Einfamilienhaus"
                                value="Einfamilienhaus"
                        ></v-radio>
                        <v-radio
                                label="Mehrfamilienhaus"
                                value="Mehrfamilienhaus"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Aufteilung bebaute Fläche in m2 pro Stockwerke?"
                            hint="Keller , EG , 1 OG , 2 OG , Dachgeschoss /Mansarde etc.? – Angabe der bebauten Fläche (m2) pro Stock"
                            v-model="stockwerke"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Keller bewohnbar ? Wenn ja wieviel m2 sind bewohnbar ?"
                            hint="Angabe der bebauten Fläche (m2) pro Stock"
                            v-model="keller"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="bauweise" :rules="genericRules">
                        <template v-slot:label>
                            <div>Bauweise (Massiv – gemischt – Holz)</div>
                        </template>
                        <v-radio
                                label="Massiv"
                                value="Massiv"
                        ></v-radio>
                        <v-radio
                                label="gemischt"
                                value="gemischt"
                        ></v-radio>
                        <v-radio
                                label="Holz"
                                value="Holz"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Wohnnutzfläche m² (Innenfläche)"
                            hint="Keller (wenn bewohnbar), EG, 1 OG, 2 OG"
                            :persistent-hint="true"
                            v-model="wohnnutz"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>


                <v-col cols="12" md="6">
                    <v-radio-group v-model="garage" :rules="genericRules">
                        <template v-slot:label>
                            <div>Garage/Carport vorhanden ?</div>
                        </template>
                        <v-radio
                                label="Ja, Direkt mit dem Gebäude verbunden"
                                value="Ja, Direkt mit dem Gebäude verbunden"
                        ></v-radio>
                        <v-radio
                                label="Ja, Freistehend"
                                value="Ja, Freistehend"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Nebengebäude wie zb. Werkzeughaus, Schuppen , andere Gebäude über 30 m2 ?  Bauweise ?"
                            v-model="nebengebaude"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="sauna" :rules="genericRules">
                        <template v-slot:label>
                            <div>Sauna, Infrarotkabine oder andere erwähnenswerte Einrichtungen vorhanden ? </div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Haustiere vorhanden? Welche?"
                            v-model="haustiere"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="verglasung" :rules="genericRules">
                        <template v-slot:label>
                            <div>Gibt es spezielle Verglasungen im /am Gebäude oder am Grundstück ? Eventuell einen Wintergarten ?</div>
                        </template>
                        <v-radio
                                label="Ja, Angebaut"
                                value="Ja, Angebaut"
                        ></v-radio>
                        <v-radio
                                label="Ja, Freistehend"
                                value="Ja, Freistehend"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Swimmingpool /Schwimmbecken mit /ohne Überdachung und wenn ja, Wert inkl. Pooltechnik , Zubehör und Überdachung ?"
                            v-model="pool"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Heizanlage , Öltank vorhanden ? Wenn ja, Fassungsvermögen ? bzw.  wie wird geheizt ?"
                            v-model="heizanlage"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Solar- /Photovoltaikanlage vorhanden ? Falls ja, Wert der Anlage ? Wo montiert ?"
                            v-model="solar"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea
                            label="Gibt es sonst noch etwas Erwähnenswertes was Ihnen einfällt ?"
                            v-model="sonstiges"
                    ></v-textarea>
                </v-col>
                <v-col cols="12" md="4">
                    <v-radio-group v-model="gerate" :rules="genericRules">
                        <template v-slot:label>
                            <div>Besteht Interesse an einer Geräteversicherung ?</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="4">
                    <v-radio-group v-model="pooltechnik" :rules="genericRules">
                        <template v-slot:label>
                            <div>Besteht Interesse an einer Pooltechnikversicherung ?</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="4">
                    <v-radio-group v-model="katastrophe" :rules="genericRules">
                        <template v-slot:label>
                            <div>Katastrophenschutzdeckung zbs. Hochwasser… über € 10.000 notwendig?</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="4">
                    <v-radio-group v-model="selbstbehalt" :rules="genericRules">
                        <template v-slot:label>
                            <div>Selbstbehalt gewünscht ?</div>
                        </template>
                        <v-radio
                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "Eigenheimversicherung",
        data() {
            return {
                menu: false,
                testvar: null,
                genericRules: [
                    (v) => !!v || 'Muss ausgefüllt werden'
                ],
                nameRules: [
                    v => !!v || 'Name wird benötigt'
                ],
            }
        },
        methods: {
            checkFormValid() {
                return this.$refs.eigenheimForm.validate();
            }
        },
        computed: {
            ...mapFields([
                'risikoAdresse',
                'baujahr',
                'bewohnt',
                'stockwerke',
                'keller',
                'wohnnutz',
                'bauweise',
                'garage',
                'nebengebaude',
                'sauna',
                'haustiere',
                'verglasung',
                'pool',
                'heizanlage',
                'solar',
                'sonstiges',
                'gerate',
                'pooltechnik',
                'katastrophe',
                'selbstbehalt',
            ]),
        }
    }
</script>

<style scoped>

</style>
