<template>
  <v-form ref="stammdatenFormRef" v-model="stammdatenValid">
    <v-container>
      <v-alert
              border="top"
              colored-border
              color="secondary"
      >
        <h3 class="headline">
         Stammdaten
        </h3>
        <div>Geben Sie bitte ihre persönlichen Daten ein.</div>
      </v-alert>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
                  prepend-icon="mdi-account-box-outline"
                  label="Vorname*"
                  v-model="vorname"
                  :rules="nameRules"
                  required
                  name="vorname"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
                  prepend-icon="mdi-account-box"
                  label="Nachname*"
                  v-model="nachname"
                  :rules="nameRules"
                  required
                  name="nachname"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
                  prepend-icon="mdi-card-account-mail"
                  label="E-Mail*"
                  v-model="email"
                  :rules="emailRules"
                  required
                  name="mail"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                      v-model="date"
                      label="Geburtsdatum*"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="genericRules"
                      v-bind="attrs"
                      v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                    ref="picker"
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="save"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
                  prepend-icon="mdi-briefcase"
                  label="Berufliche Tätigkeit*"
                  v-model="beruf"
                  :rules="berufRules"
                  required
                  name="beruf"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field

                  prepend-icon="mdi-phone"
                  label="Telefonnummer*"
                  v-model="telefon"
                  :rules="telefonRules"
                  required
                  name="telefon"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
  import { mapFields } from 'vuex-map-fields';
  export default {
    components: {
    },data() {
      return {
        menu: false,
        date: '',
        requiredRule: value => !!value || "Muss ausgefüllt werden.",
        nameRules: [
          (v) => !!v || 'Name wird benötigt'
        ],
        berufRules: [
          (v) => !!v || 'Beruf wird benötigt'
        ],
        genericRules: [
          (v) => !!v || 'Muss ausgefüllt werden'
        ],
        telefonRules: [
          (v) => !!v || 'Telefon / Handynummer wird benötigt',
          // eslint-disable-next-line no-useless-escape
          (v) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(v) || 'Keine gültige Telefonnummer'
        ],
        emailRules: [
          (v) => !!v || 'E-mail wird benötigt',
          // eslint-disable-next-line no-useless-escape
          (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Keine gültige E-Mail'
        ],
      };
    },
    watch: {
      menu(val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },
    methods: {
      checkFormValid() {
        localStorage.setItem('birthDate', new Date(this.date).toLocaleDateString())
        return this.$refs.stammdatenFormRef.validate();
      },
      save(date) {
        this.$refs.menu.save(date)
      }
    },
    computed: {
      ...mapFields([
        'vorname',
        'nachname',
        'telefon',
        'email',
        'beruf',
        'stammdatenValid',
      ]),
    }
  }
</script>
