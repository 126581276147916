export default class EigenheimTemplates{
    getEigenheimTable(state){
        return `
            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
              <tbody>
                <tr>
                  <td align="left">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td> 
                               <table class="styled-table">
                                    <thead>
                                        <tr>
                                            <th>Eintrag</th>
                                            <th>Auswahl</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>genaue Risikoadresse / Anschrift ?</td>
                                            <td>${state.risikoAdresse}</td>
                                        </tr>
                                        <tr>
                                            <td>Baujahr/td>
                                            <td>${state.baujahr}</td>
                                        </tr>
                                        <tr>
                                            <td>Ist das Gebäude mehr als 270 Tage über Nacht bewohnt im Jahr ? \\n (Oder dient es als Sommerhaus /Zweitwohnsitz und ist weniger als 270 Tage bewohnt)</td>
                                            <td>${state.bewohnt}</td>
                                        </tr>
                                        <tr>
                                            <td>Wieviel Stockwerke gibt es und wie sind diese unterteilt ?</td>
                                            <td>${state.stockwerke}</td>
                                        </tr>
                                        <tr>
                                            <td>Keller bewohnbar ? Wenn ja wieviel m2 sind bewohnbar ?</td>
                                            <td>${state.keller}</td>
                                        </tr>
                                        <tr>
                                            <td>Wie teilt sich die Wohnnutzfläche m² auf die einzelnen Stockwerke auf?</td>
                                            <td>${state.wohnnutz}</td>
                                        </tr>
                                        <tr>
                                            <td>Bauweise (Massiv – gemischt – Holz)</td>
                                            <td>${state.bauweise}</td>
                                        </tr>
                                        <tr>
                                            <td>Garage/Carport vorhanden ?</td>
                                            <td>${state.garage}</td>
                                        </tr>
                                        <tr>
                                            <td>Nebengebäude wie zb. Werkzeughaus, Schuppen , andere Gebäude über 30 m2 ?  Bauweise ?</td>
                                            <td>${state.nebengebaude}</td>
                                        </tr>
                                        <tr>
                                            <td>Sauna, Infrarotkabine oder andere erwähnenswerte Einrichtungen vorhanden?</td>
                                            <td>${state.sauna}</td>
                                        </tr>
                                        <tr>
                                            <td>Haustiere vorhanden? Welche?</td>
                                            <td>${state.haustiere}</td>
                                        </tr>
                                        <tr>
                                            <td>Gibt es spezielle Verglasungen im /am Gebäude oder am Grundstück ? Eventuell einen Wintergarten ?</td>
                                            <td>${state.verglasung}</td>
                                        </tr>
                                        <tr>
                                            <td>Swimmingpool/Schwimmbecken mit/ohne Überdachung und wenn ja, Wert inkl. Pooltechnik, Zubehör und Überdachung?</td>
                                            <td>${state.pool}</td>
                                        </tr>
                                        <tr>
                                            <td>Heizanlage , Öltank vorhanden? Wenn ja, Fassungsvermögen? bzw.  wie wird geheizt?</td>
                                            <td>${state.heizanlage}</td>
                                        </tr>
                                        <tr>
                                            <td>Solar-/Photovoltaikanlage vorhanden? Falls ja, Wert der Anlage? Wo montiert?</td>
                                            <td>${state.solar}</td>
                                        </tr>
                                        <tr>
                                            <td>Besteht Interesse an einer Geräteversicherung?</td>
                                            <td>${state.gerate}</td>
                                        </tr>
                                        <tr>
                                            <td>Besteht Interesse an einer Pooltechnikversicherung?</td>
                                            <td>${state.pooltechnik}</td>
                                        </tr>
                                        <tr>
                                            <td>Katastrophenschutzdeckung zbs. Hochwasser… über € 10.000 notwendig?</td>
                                            <td>${state.katastrophe}</td>
                                        </tr>
                                        <tr>
                                            <td>Selbstbehalt gewünscht?</td>
                                            <td>${state.selbstbehalt}</td>
                                        </tr>
                                    </tbody>
                                </table>
                           </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>`;
    }
}
