<template>
  <v-app>
    <v-app-bar
      app
      color="light"
      light
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://rzavsky.at/wp-content/uploads/2019/08/RZV_LOGO_POSITIV.svg"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://rzavsky.at/"
        text
        x-small
      >
        <v-icon small>mdi-home</v-icon>
        <span class="mr-1 ml-1">Zurück zur Homepage</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container :px-0="$vuetify.breakpoint.xs">
        <Stepper />
      </v-container>
    </v-main>
      <v-footer
              dark
              padless
      >
          <v-card

                  width="100%"
                  flat
                  tile
                  class="primary white--text text-center"
          >
              <v-card-text>
                  <v-btn large href="https://www.facebook.com/rzavsky.versicherungskanzlei/" class="mx-4 white--text" icon>
                      <v-icon large>
                          mdi-facebook
                      </v-icon>
                  </v-btn>
                  <v-btn large href="https://www.linkedin.com/company/rzavsky-versicherungskanzlei/about/" class="mx-4 white--text" icon>
                      <v-icon large>
                          mdi-linkedin
                      </v-icon>
                  </v-btn >
                  <v-btn large href="https://www.xing.com/profile/Michael_Rzavsky/" class="mx-4 white--text" icon>
                      <v-icon  large>
                          mdi-xing
                      </v-icon>
                  </v-btn>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="white--text">
                  <a class="white--text" href="https://rzavsky.at/impressum/">IMPRESSUM & MAKLERINFORMATIONEN</a> |
                  <a class="white--text" href="https://rzavsky.at/privacy-policy/">Datenschutz</a> |
                  <a class="white--text" href="https://rzavsky.at/allgemeine-geschaeftsbedingungen/">Datenschutz</a>
                  <br>
                  ©{{ new Date().getFullYear() }} — <strong> MICHAEL RZAVSKY · VERSICHERUNGSMAKLER & BERATER IN VERSICHERUNGSANGELEGENHEITEN</strong>
              </v-card-text>
          </v-card>
      </v-footer>
  </v-app>
</template>

<script>
import Stepper from './components/Stepper';
import {Framework as $vuetify} from "vuetify";
export default {
  name: 'App',

  components: {
    Stepper
  },

  data: () => ({
    vuetify: $vuetify
  }),
};
</script>
<style>

</style>
