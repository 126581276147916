<template>
    <v-sheet
            elevation="6"
            color="#5A6476">
        <v-toolbar color="#5A6476"
        >
            <v-toolbar-title class="white--text">Erstkontakt Anfrage</v-toolbar-title>
        </v-toolbar>
    <v-stepper ref="stepper" v-model="e1" style="overflow: visible;">
        <v-stepper-header>
            <v-stepper-step :editable="!isComplete" :complete="e1 > 1" step="1">Vorabinformation</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="!isComplete && e1 >= 2" :complete="e1 > 2" step="2">Vorteile</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="!isComplete && e1 >= 3" :complete="e1 > 3" step="3">Stammdaten</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="!isComplete && e1 >= 4" :complete="e1 > 4" step="4">Versicherungstyp</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :editable="!isComplete && e1 >= 5" :complete="e1 > 5" step="5">Versicherung</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content :class="{'px-1': $vuetify.breakpoint.xs}" step="1">
                <Vorabinformation/>
                <div class="d-flex justify-end">
                    <v-btn @click="decrement()" text>Zurück</v-btn>
                    <v-btn class="" color="primary" @click="increment()">Weiter <v-icon>mdi-chevron-right</v-icon></v-btn>
                </div>
            </v-stepper-content>

            <v-stepper-content :class="{'px-1': $vuetify.breakpoint.xs}" step="2">
                <Vorteile/>
                <div class="d-flex justify-end">
                    <v-btn @click="decrement()" text>Zurück</v-btn>
                    <v-btn class="" color="primary" @click="increment()">Weiter <v-icon>mdi-chevron-right</v-icon></v-btn>
                </div>
            </v-stepper-content>

            <v-stepper-content :class="{'px-1': $vuetify.breakpoint.xs}" step="3">
                <Stammdaten ref="stammdatenRef"/>
                <div class="d-flex justify-end">
                    <v-btn @click="decrement()" text>Zurück</v-btn>
                    <v-btn class="" color="primary" @click="increment()">Weiter <v-icon>mdi-chevron-right</v-icon></v-btn>
                </div>

            </v-stepper-content>

            <v-stepper-content :class="{'px-1': $vuetify.breakpoint.xs}" step="4">
                <Anliegen/>
                <div class="d-flex justify-end">
                    <v-btn @click="decrement()" text>Zurück</v-btn>
                    <v-btn class="" color="primary" @click="increment()">Weiter <v-icon>mdi-chevron-right</v-icon></v-btn>
                </div>
            </v-stepper-content>

            <v-stepper-content :class="{'px-1': $vuetify.breakpoint.xs}" step="5">
                <KFZ ref="kfzRef" v-if="this.$store.state.anliegen === 'KFZ Versicherung'"/>
                <Krankenversicherung ref="krankenRef" v-if="this.$store.state.anliegen === 'Krankenversicherung'"/>
                <Haushaltsversicherung ref="haushaltsRef" v-if="this.$store.state.anliegen === 'Haushaltsversicherung'"/>
                <Eigenheimversicherung ref="eigenheimRef" v-if="this.$store.state.anliegen === 'Eigenheimversicherung'"/>
                <div class="d-flex justify-end">
                    <v-btn @click="decrement()" text>Zurück</v-btn>
                    <v-btn  :loading="loading"
                            :disabled="loading"
                            color="primary"
                            @click="execute()">Absenden <v-icon>mdi-send</v-icon></v-btn>
                </div>
            </v-stepper-content>
            <v-stepper-content step="6">
                <Done/>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
        <v-snackbar v-model="snackbar">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                        color="#5A6476"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                >
                    OK
                </v-btn>
            </template>
        </v-snackbar>
    </v-sheet>
</template>
<script>
    import Stammdaten from "@/components/Stammdaten";
    import Vorteile from "@/components/Vorteile";
    import Vorabinformation from "@/components/Vorabinformation";
    import Anliegen from "@/components/Anliegen";
    import KFZ from "@/components/versicherungen/KFZ";
    import Done from "@/components/Done";
    import EmailTemplates from "@/components/services/email.service";
    import Krankenversicherung from "@/components/versicherungen/Krankenversicherung";
    import Haushaltsversicherung from "@/components/versicherungen/Haushaltsversicherung";
    import Eigenheimversicherung from "@/components/versicherungen/Eigenheim";

    export default {
        components: {
            Krankenversicherung,
            KFZ,
            Vorabinformation,
            Vorteile,
            Stammdaten,
            Anliegen,
            Done,
            Haushaltsversicherung,
            Eigenheimversicherung
        }, data() {
            return {
                e1: 1,
                sent: false,
                snackbar: false,
                snackbarText: ``,
                isComplete: false,
                isEditable: true,
                loader: null,
                loading: false,
            };
        },
        watch: {
            menu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
            '$store.state.stammdatenValid': function() {
                console.log(this.$store.state.stammdatenValid)
            },
            '$store.state': function() {
                console.log(this.$store.state)
            }
        },
        methods: {
            decideAnliegen() {
                this.e1++;
                console.log(this.$store.state.anliegen);
            },
            selectedAnliegen() {
                return this.$store.state.anliegen
            },
            decrement() {
                this.e1--;
            },
            increment() {
                if(this.e1 == 3 && this.$refs.stammdatenRef.checkFormValid()){
                    this.approveIncrement();
                }
                else if(this.e1 == "1"){
                    this.approveIncrement();
                }
                else if(this.e1 == "2"){
                    this.approveIncrement();
                }
                else if(this.e1 == "4"){
                    this.approveIncrement();
                }
                else{
                    this.snackbarText = 'Alle Felder müssen ausgefüllt werden.';
                    this.snackbar = true;
                }
            },
            approveIncrement(){
                this.e1++;
                this.$vuetify.goTo(this.$refs.stepper, {
                    duration: 250,
                    easing: 'easeOutCubic'
                })
            },
            execute(){
                const vals = this.$store.state;
                console.log(vals)
                if(vals.anliegen === 'KFZ Versicherung') {
                    if(!this.$refs.kfzRef.checkFormValid()) {
                        this.snackbarText = 'Alle Felder müssen ausgefüllt werden.';
                        this.snackbar = true;
                        return;
                    }
                }
                if(vals.anliegen === 'Haushaltsversicherung') {
                    if(!this.$refs.haushaltsRef.checkFormValid()) {
                        this.snackbarText = 'Alle Felder müssen ausgefüllt werden.';
                        this.snackbar = true;
                        return;
                    }
                }

                if(vals.anliegen === 'Krankenversicherung') {
                    if(!this.$refs.krankenRef.checkFormValid()) {
                        this.snackbarText = 'Alle Felder müssen ausgefüllt werden.';
                        this.snackbar = true;
                        return;
                    }
                }

                if(vals.anliegen === 'Eigenheimversicherung') {
                    if(!this.$refs.eigenheimRef.checkFormValid()) {
                        this.snackbarText = 'Alle Felder müssen ausgefüllt werden.';
                        this.snackbar = true;
                        return;
                    }
                }
                this.loader = 'loading';
                this.axios
                    .post(
                        "https://anfrage.rzavsky.at/mail.php",
                        {
                            name: vals.vorname + " " + vals.nachname,
                            content: new EmailTemplates().getKfztemplate(vals)
                        },
                        {headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json',
                            },
                            auth: {
                                username: 'testenv',
                                password: 'Vorschau12345'
                            }
                        }
                    )
                    .then(res => {
                        this.loader = 'loading';
                        this.sent = true;
                        this.e1++;
                        this.isComplete = true;
                        this.$vuetify.goTo(this.$refs.stepper, {
                            duration: 250,
                            easing: 'easeOutCubic'
                        })
                        this.snackbarText = 'Ihre Anfrage wurde erfolgreich eingereicht.';
                        this.snackbar = true;
                        console.log(res);
                    }).catch(x =>{
                        this.loader = null;
                        this.loading = false;
                        this.snackbarText = 'Ihre Anfrage konnte nicht verarbeitet werden.';
                        this.snackbar = true;
                        console.log(x);
                });
            }
        }
    }
</script>
