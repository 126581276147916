<template>
    <v-form ref="kfzForm">
        <v-alert
                border="top"
                colored-border
                color="secondary"
        >
            <h3 class="headline">
                KFZ Versicherung
            </h3>
            <div>Füllen Sie bitte folgende Daten zu ihrer Versicherung aus.</div>
        </v-alert>
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Bonus / Malus Stufe"
                            v-model="malus"
                            prefix="Stufe"
                            type="number"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Arbeitgeber im Öffentlichen Dienst? Wenn Ja, wo genau"
                            v-model="arbeitgeber"
                            :rules="genericRules"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                    <v-select
                            :items="['PKW', 'Motorrad', 'Moped']"
                            v-model="fahrzeug"
                            label="Fahrzeug"
                            :rules="genericRules"
                            required
                    ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                            label="ccm (falls Motorad)"
                            v-model="ccm"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                            :items="['Diesel', 'Benziner', 'Elektro']"
                            v-model="antrieb"
                            label="Antriebsart"
                            :rules="genericRules"
                            required
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Marke"
                            v-model="marke"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Type"
                            v-model="type"
                            :rules="genericRules"
                            required
                            hint="Type des Fahrzeugs z.B. Limousine"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                    <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="date"
                                    label="Erstzulassung"
                                    prepend-icon="mdi-calendar"
                                    :rules="genericRules"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                                ref="picker"
                                v-model="date"
                                :max="new Date().toISOString().substr(0, 10)"
                                min="1950-01-01"
                                @change="save"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="KW"
                            type="number"
                            suffix="KW"
                            :rules="genericRules"
                            v-model="ps"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Co2 Wert"
                            suffix="Neu g/km"
                            v-model="co2"
                            :rules="genericRules"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            label="Sitzplätze"
                            type="number"
                            :rules="genericRules"
                            v-model="platz"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <v-select
                            :items="['Haftpflicht', 'Haftpflicht und Vollkasko', 'Haftpflicht und Teilkasko']"
                            v-model="deckung"
                            :rules="genericRules"
                            label="Deckung"
                            required
                    ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="selbstbehalt"  :rules="genericRules"
                                   hint="Wenn Sie keinen generellen Selbstbehalt wünschen, entfällt der Selbstbehalt bei Naturgewalten wie zbs. Hagel, Diebstahl und Schäden durch Tiere !" :persistent-hint="true">

                        <template v-slot:label>
                            <div>Selbstbehalt Kasko: bis € 300 über € 300 auf alle Schäden ?</div>
                        </template>
                        <v-radio

                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field  label="Listenneupreis"
                                   prefix="€"
                                   :rules="genericRules"
                                   type="number"
                                       v-model="preis"/>
                </v-col>
                <v-col cols="12" md="6">
                    <v-radio-group v-model="leasing"  :rules="genericRules">

                        <template v-slot:label>
                            <div>Leasingfahrzeug ?</div>
                        </template>
                        <v-radio

                                label="Ja"
                                value="Ja"
                        ></v-radio>
                        <v-radio
                                label="Nein"
                                value="Nein"
                        ></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                            label="Sonderausstattung"
                            type="text"
                            :rules="genericRules"
                            v-model="sonderausstattung"
                            required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-textarea
                            label="Sonstige Wünsche (Optional)"
                            v-model="sonstiges"
                            counter
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "KFZ",
        data() {
            return {
                date: '',
                menu: false,
                genericRules: [
                    (v) => !!v || 'Muss ausgefüllt werden'
                ]
            }
        },
        watch: {
            menu(val) {
                val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
            },
        },
        methods: {
            checkFormValid() {
                localStorage.setItem('erstzulassung', new Date(this.date).toLocaleDateString())
                return this.$refs.kfzForm.validate();
            },
            save(date) {
                this.$refs.menu.save(date)
            }
        },
        computed: {
            ...mapFields([
                'malus',
                'selbstbehalt',
                'arbeitgeber',
                'fahrzeug',
                'marke',
                'type',
                'antrieb',
                'ps',
                'co2',
                'platz',
                'ccm',
                'deckung',
                'preis',
                'sonderausstattung',
                'sonstiges',
                'leasing'
            ]),
        }
    }
</script>

<style scoped>

</style>
