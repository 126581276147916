import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields';
Vue.config.productionTip = false
Vue.use(VueAxios, axios);
Vue.use(Vuex);
// eslint-disable-next-line no-unused-vars
const store = new Vuex.Store({
  state: {
    vorname: '',
    nachname: '',
  },
  getters: {
    // Add the `getField` getter to the
    // `getters` of your Vuex store instance.
    getField,
  },
  mutations: {
    // Add the `updateField` mutation to the
    // `mutations` of your Vuex store instance.
    updateField,
  },
})
new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
