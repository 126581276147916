<template>
    <v-alert
            border="top"
            colored-border
            color="success"
    >
        <h3 class="headline">
            Unsere Vorteile
        </h3>
        <div class="text-wrap pb-3">Welche Vorteile hätte ich als Kunde von einer Gesamtkundenbetreuung über die Rzavsky Versicherungskanzlei ?</div>
        <p >

            <v-icon color="primary">mdi-account-heart</v-icon> Wir verstehen uns als ihr Versicherungsmakler und Berater in allen Versicherungslösungen. Individuelle Beratung und maßgeschneiderte Konzepte sehen wir als unsere Kernkompetenz.
            <v-divider class="my-3 py-1"></v-divider>
            <v-icon color="primary">mdi-finance</v-icon> Wir sind unabhängig und finden für sie die besten Versicherungslösungen nach einem optimalen Preis/Leistungsprinzip
            <v-divider class="my-3 py-1"></v-divider>
            <v-icon color="primary">mdi-gavel</v-icon> Wir sind gesetzlich verpflichtet, sie bestens zu beraten (Best-Advice Pflicht).
            <v-divider class="my-3 py-1"></v-divider>
            <v-icon color="primary">mdi-cash</v-icon> Durch ein optimales Gesamtberatungskonzept können sie unteranderem etwaige Doppelversicherungen vermeiden
            <v-divider class="my-3 py-1"></v-divider>
            <v-icon color="primary">mdi-gavel</v-icon> Unterstützung im Schadensfall
            <v-divider class="my-3 py-1"></v-divider>
            <v-icon  color="primary">mdi-account-lock</v-icon> Ein Ansprechpartner in allen Versicherungsangelegenheiten
            <v-alert
                    text
                    dense
                    color="success"
                    icon="mdi-check"
                    class="mt-6"
                    border="left"
            >
                Wenn das für sie in Ordnung ist, sind sie bei uns richtig !
            </v-alert>

        </p>
    </v-alert>
</template>

<script>
    export default {
        name: "Vorteile"
    }
</script>

<style scoped>

</style>
