export default class KfzTemplates {
    getKfzTable(state){
        return `
            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
              <tbody>
                <tr>
                  <td align="left">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td> 
                               <table class="styled-table">
                                    <thead>
                                        <tr>
                                            <th>Eintrag</th>
                                            <th>Auswahl</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>In welcher Bonus/Malus Stufe befinden sie sich?</td>
                                            <td>${state.malus}</td>
                                        </tr>
                                        <tr>
                                            <td>Arbeitgeber im öffentlichen Dienst ? Wenn ja, wo genau ?</td>
                                            <td>${state.arbeitgeber}</td>
                                        </tr>
                                        <tr>
                                            <td>PKW/Motorrad/Moped </td>
                                            <td>${state.fahrzeug}</td>
                                        </tr>
                                        <tr>
                                            <td>Marke</td>
                                            <td>${state.marke}</td>
                                        </tr>
                                        <tr>
                                            <td>Type</td>
                                            <td>${state.type}</td>
                                        </tr>
                                        <tr>
                                            <td>Arbeitgeber im öffentlichen Dienst ? Wenn ja, wo genau ?</td>
                                            <td>${state.arbeitgeber}</td>
                                        </tr>
                                        <tr>
                                            <td>Antriebsart (Diesel, Benziner, Elektro…)</td>
                                            <td>${state.antrieb}</td>
                                        </tr>
                                        <tr>
                                            <td>Erstzulassung </td>
                                            <td>${localStorage.getItem('erstzulassung')}</td>
                                        </tr>
                                        <tr>
                                            <td>Listenneupreis </td>
                                            <td>${state.preis}</td>
                                        </tr>
                                        <tr>
                                            <td>Leasingfahrzeug </td>
                                            <td>${state.leasing}</td>
                                        </tr>
                                        
                                        <tr>
                                            <td>Selbstbehalt Kasko: bis € 300 über € 300 auf alle Schäden </td>
                                            <td>${state.selbstbehalt}</td>
                                        </tr>
                                        <tr>
                                            <td>Sonderausstattung </td>
                                            <td>${state.sonderausstattung}</td>
                                        </tr>
                                        <tr>
                                            <td>Sonstige Wünsche </td>
                                            <td>${state.sonstiges}</td>
                                        </tr>
                                    </tbody>
                                </table>
                           </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>`;
    }
}
