export default class HaushaltsTemplates{
    getHaushaltsTable(state){
        return `
            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
              <tbody>
                <tr>
                  <td align="left">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <td> 
                               <table class="styled-table">
                                    <thead>
                                        <tr>
                                            <th>Eintrag</th>
                                            <th>Auswahl</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>m² Anzahl Wohnung ?</td>
                                            <td>${state.quadratMeter}</td>
                                        </tr>
                                        <tr>
                                            <td>genaue Risikoadresse / Anschrift ?</td>
                                            <td>${state.risikoAdresse}</td>
                                        </tr>
                                        <tr>
                                            <td>Loggia vorhanden ? Wenn ja, wie groß?</td>
                                            <td>${state.loggia}</td>
                                        </tr>
                                        <tr>
                                            <td>Wohnung mit Garten ?</td>
                                            <td>${state.mitGarten}</td>
                                        </tr>
                                        <tr>
                                            <td>Wohnung auch teilweise als Büro genutzt? Wenn ja, wieviel m2 werden als Büro genutzt?</td>
                                            <td>${state.wohnungBuro}</td>
                                        </tr>
                                        <tr>
                                            <td>Weitere Räume wie Abstellräume , Keller, Dachboden oder andere Räumlichkeiten vorhanden?</td>
                                            <td>${state.weitereRaume}</td>
                                        </tr>
                                        <tr>
                                            <td>Befindet sich die Wohnung in einem Ein- / Zweifamilienhaus oder Mehrfamilienhaus die mehr als 270 Tage bewohnt wird ?</td>
                                            <td>${state.einZweiFamilien}</td>
                                        </tr>
                                        <tr>
                                            <td>Sicherheitstüre gemäß ÖNORM B 5338 vorhanden ? (Zertifikat/Rechnung müsste vorhanden sein)</td>
                                            <td>${state.sicherheitstur}</td>
                                        </tr>
                                        <tr>
                                            <td>Wertgegenstände zbs. Uhren , Schmuck , Gold , Briefmarken, Münzsammlungen oder dergleichen vorhanden ? Wenn ja, welcher Gesamtwert ?</td>
                                            <td>${state.wertgegenstande}</td>
                                        </tr>
                                        <tr>
                                            <td>Bargeld unter einfachen Verschluss (nicht sichtbar) über € 6.000? </td>
                                            <td>${state.bargeld}</td>
                                        </tr>
                                        <tr>
                                            <td>Spezielle Wertegegenstände /Einzelstücke von hohem Wert ? Vielleicht Musikinstrumente ? Wenn ja, welcher Anschaffungswert ?</td>
                                            <td>${state.spezielle}</td>
                                        </tr>
                                        <tr>
                                            <td>Safe mit mind. 100kg vorhanden ?</td>
                                            <td>${state.safe}</td>
                                        </tr>
                                        <tr>
                                            <td>Antiquitäten , Kunstgegenstände oder andere erwähnenswerte Objekte von speziellem oder künstlerischem Wert / Verkehrswert und wenn ja,  in welcher Höhe ?</td>
                                            <td>${state.antiquitaten}</td>
                                        </tr>
                                        <tr>
                                            <td>Fahrräder / Rennräder vorhanden ? sonstige speziellen Sportgeräte ? Wenn ja, bitte Wertangaben und Alter angeben</td>
                                            <td>${state.fahrrader}</td>
                                        </tr>
                                        <tr>
                                            <td>Haustiere ? Hunde, Katzen , etc.</td>
                                            <td>${state.haustiere}</td>
                                        </tr>
                                        <tr>
                                            <td>Spezielle Verglasungen vorhanden ?-  Kunstverglasungen, Dachverglasungen , Loggia/Balkonverglasungen ?</td>
                                            <td>${state.verglasung}</td>
                                        </tr>
                                        <tr>
                                            <td>Gibt es sonst noch etwas Erwähnenswertes ? Irgendetwas in der Wohnung oder in Ihrem Eigentum was Sie für Erwähnenswert halten ? Irgendeine spezielle Sammlung ?</td>
                                            <td>${state.sonstiges}</td>
                                        </tr>
                                        <tr>
                                            <td>Bewohnen Sie die Wohnung alleine oder mit Ehefrau, Kinder, Lebensgefährtin, anderen Verwandten, Mitbewohner /Kollegen ? Bitte das Alter des Kindes oder der Kinder angeben</td>
                                            <td>${state.alleine}</td>
                                        </tr>
                                        <tr>
                                            <td>Soll optional Schlüsselverlust versichert werden?</td>
                                            <td>${state.schlussel}</td>
                                        </tr>
                                        <tr>
                                            <td>Besteht Interesse an einer Geräteversicherung ?</td>
                                            <td>${state.gerate}</td>
                                        </tr>
                                        <tr>
                                            <td>Selbstbehalt gewünscht ?</td>
                                            <td>${state.selbstbehalt}</td>
                                        </tr>
                                        <tr>
                                            <td>Ausstattungskategorie ?</td>
                                            <td>${state.ausstattung}</td>
                                        </tr>
                                    </tbody>
                                </table>
                           </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>`;
    }
}
