<template>
    <div>


        <div class="d-flex align-center justify-center py-5">
            <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="https://rzavsky.at/wp-content/uploads/2019/08/RZV_LOGO_POSITIV.svg"
                    transition="scale-transition"
                    width="400"
            />

        </div>
        <div class="d-flex align-center justify-center py-5">

        </div>
        <v-alert
                color="success"
                dark
                icon="mdi-check"
                border="left"
                prominent
        >

            <h3 class="headline">
                Versicherungsantrag eingereicht!
            </h3>
            <div>Die Daten wurden an Ihren Versicherungsberater gesendet, wir werden mit Ihnen in Kontakt treten.</div>

        </v-alert>
        <div class="d-flex align-center justify-center py-5">
        <v-btn
                href="https://rzavsky.at/"
                text
        >
            <v-icon>mdi-home</v-icon>
            <span class="mr-2">Zurück zur Homepage</span>
        </v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Done"
    }
</script>

<style scoped>

</style>
