<template>
    <div>
        <v-alert
                border="top"
                colored-border
                color="secondary"
        >
            <h3 class="headline">
                Zur Vorabinformation
            </h3>
            <span class="d-flex align-center justify-center py-3 py-3 float-lg-left">
                <v-img
                        alt="Vuetify Logo"
                        class="shrink mr-2 my-3 py-3"
                        contain
                        src="https://rzavsky.at/wp-content/uploads/2019/09/RZV_IMAGE_MICHI-1.png"
                        transition="slide-y-transition"
                        width="150"
                />
            </span>
            <p class="my-3 py-3 pt-5">
                <br>
                Unsere Unternehmensphilosophie ist die Gesamtberatung und Betreuung aller bestehenden und zukünftigen Versicherungsverträge, um auch alle Aspekte der Risikosituation/des täglichen Lebens miteinbeziehen zu können und hierfür sind wir ausschließlich im Auftrag des Kundin tätig.
                <br>
                <br>
                Wir führen eine Risikoanalyse durch und holen Angebote zu den entsprechenden gewünschten Produkten ein und würden diese dann mit Ihnen persönlich, per Mail oder über Videoberatung besprechen.
            </p >
        </v-alert>
    </div>

</template>

<script>
    export default {
        name: "Vorabinformation"
    }
</script>

<style scoped>

</style>
