<template>
    <div>
        <v-alert
                border="top"
                colored-border
                color="secondary"
        >
            <h3 class="headline">
                Versicherungstyp
            </h3>
            <div>Wählen Sie bitte Ihren gewünschten Versicherungstyp aus.</div>
        </v-alert>
        <v-radio-group
                v-model="anliegen"
                mandatory
        >
            <v-radio
                    label="KFZ Versicherung"
                    value="KFZ Versicherung"
            ></v-radio>
            <v-radio
                    label="Haushaltsversicherung"
                    value="Haushaltsversicherung"
            ></v-radio>
           <v-radio
                    label="Eigenheimversicherung"
                    value="Eigenheimversicherung"
            ></v-radio>
            <v-radio
                    label="Krankenversicherung"
                    value="Krankenversicherung"
            ></v-radio>
        </v-radio-group>
    </div>

</template>

<script>
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "Vorteile",
        computed: {
            ...mapFields([
                'anliegen',
            ]),
        }
    }
</script>

<style scoped>

</style>
